import * as React from 'react';
import { useEffect } from 'react';

import { HeroNav } from '../hero/hero-nav';
import { StorageCTA } from './storage-cta';
import HeroImage from './storage-hero-image';
import { StoringQuoteForm } from './storing-quote-form';

const StorageHero = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      var f = document.createElement("iframe");  
      f.src = 'https://forms.zohopublic.com.au/frontlineremovals/form/FLRStorageform/formperma/jy5kH1nQLI7fTTd-ooOEvL7RlYK_b8oj3Mc8NWPPrQU?zf_rszfm=1'; 
f.style.border="none";                                           
f.style.height="655px";
f.style.width="100%";
f.style.transition="all 0.5s ease";// No I18N
var d = document.getElementById("storage-page-top-form");
d.appendChild(f);
// window.addEventListener('message', function (){
// var zf_ifrm_data = typeof event.data === 'string' ? event.data.split("|") : null ;
// var zf_perma = zf_ifrm_data && zf_ifrm_data[0];
// // var zf_ifrm_ht_nw = zf_ifrm_data && z( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
// var iframe = document.getElementById("zf_div_hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM").getElementsByTagName("iframe")[0];
// if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
// // var prevIframeHeight = iframe.style.height;
// // if ( prevIframeHeight != zf_ifrm_ht_nw ) {
// // iframe.style.height = zf_ifrm_ht_nw;
// // }   
// }
// }, false);
      }
  
}, []);

  return(
  <header className="relative">
    <div className="absolute inset-0">
      <div className="absolute inset-0 flex">
        <HeroImage />
      </div>
    </div>
    <div className="relative flex justify-center hero-gradient">
      <div className="max-w-6xl px-6 py-4 ">
        <HeroNav />
        <div className="grid lg:grid-cols-2">
          <div className="flex flex-col justify-between col-span-1 pb-8">
            <StorageCTA />
          </div>
          <div className="flex justify-center col-span-1 ">
            <div className="w-full md:mx-auto lg:w-full">
              {/* <StoringQuoteForm
                formName="storage-page-top-form"
                id="hero-form"
                className="block transform translate-y-14"
              /> */}
              <div id="storage-page-top-form" className='mt-12'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)};

export { StorageHero };
