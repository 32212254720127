import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { IoIosCall } from 'react-icons/io';

const StorageCTA = () => {
  const {
    site: {
      siteMetadata: { phone, phoneFormatted },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
        }
      }
    }
  `);

  const isBrowser = typeof window !== 'undefined';

  return (
    <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
      <div className="flex">
        <div className="p-3 -m-3 overflow-hidden rounded-lg cta">
          <h1 className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
            Purpose-build <br />
            storage containers <br />
            for <span className="text-brand-orange">your convenience</span>
          </h1>
          <CTA />
          <div className="flex flex-col mt-4 -mx-2 ">
            <p className="flex-shrink-0 w-full px-2 sm:w-auto">
              <Link
                to="https://www.frontlineremovals.com.au/storage-solutions/#storage-page-bottom-form"
                // @ts-ignore
                offset="12"
                className="flex items-center justify-center w-full mt-4 shadow sm:w-56 hover:shadow-md button"
              >
                Get instant quote
              </Link>
            </p>
            <p className="flex-shrink-0 w-full px-2 mb-2 sm:w-auto">
              <a
                onClick={() => {
                  // @ts-ignore
                  if (isBrowser && window.fbq) {
                    // @ts-ignore
                    window.fbq('track', 'Contact');
                  }
                }}
                href={`tel:${phone}`}
                className="flex items-center justify-center w-full mt-4 bg-white shadow border-brand-orange text-brand-orange sm:w-56 hover:shadow-md button"
              >
                <IoIosCall />
                <span className="ml-2">Call: {phoneFormatted}</span>
              </a>
            </p>
          </div>

          {/* <div className="w-full max-w-lg mx-0 mt-6 sm:max-w-lg sm:pr-10">
            <div className="overflow-hidden rounded-md">
              <ReviewWidget />
            </div>
          </div> */}
        </div>
      </div>
    </article>
  );
};

function CTA() {
  return (
    <div className="w-full max-w-lg mx-0 mt-12 sm:max-w-lg sm:pr-10">
      <Link
        to="https://www.frontlineremovals.com.au/furniture-removal/"
        className="block p-6 rounded-r-md bg-brand-blue hover:shadow-md"
      >
        <div className="px-3 py-1 leading-none">
          <h2 className="text-4xl font-semibold text-white uppercase font-display">
            Are you thinking about moving,
          </h2>
        </div>
        <div className="px-3 py-1 leading-none rounded bg-brand-orange">
          <h2 className="text-4xl font-semibold text-white uppercase font-display">
            find out about our moving services.
          </h2>
        </div>
      </Link>
    </div>
  );
}

export { StorageCTA };
